import React from "react";
import * as P from './parts';
import { Container } from "styled-bootstrap-grid";
import { Trans } from "react-i18next";
import Fade from 'react-reveal';

const SectionCookiePolicy = () => {
  return (
    <P.StyledSection>
      <Fade>
        <Container>
          <P.StyledSectionTitle>
            <Trans>privacy_policy_cookie_title</Trans>
          </P.StyledSectionTitle>
          <P.StyledText>
            <Trans>privacy_policy_cookie_like_many_websites</Trans>
          </P.StyledText>
          <P.StyledText>
            <Trans>privacy_policy_cookie_third_parties</Trans>
          </P.StyledText>
        </Container>
      </Fade>
    </P.StyledSection>
  );
}

export default SectionCookiePolicy;