import React from "react";
import * as P from './parts';
import { Container } from "styled-bootstrap-grid";
import { Trans } from "react-i18next";
import Fade from 'react-reveal';

const SectionPersonalInfo = () => {
  return (
    <P.StyledSection>
      <Fade>
        <Container>
          <P.StyledSectionTitle>
            <Trans>privacy_policy_personal_info_title</Trans>
          </P.StyledSectionTitle>
          <P.StyledText>
            <Trans>privacy_policy_personal_info_your_requests</Trans>
          </P.StyledText>
          <P.StyledText>
            <Trans>privacy_policy_personal_info_track_engagement</Trans>
          </P.StyledText>
        </Container>
      </Fade>
    </P.StyledSection>
  );
}

export default SectionPersonalInfo;