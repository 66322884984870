import React from "react";
import * as P from './parts';
import { Container } from "styled-bootstrap-grid";
import { Trans } from "react-i18next";
import Fade from 'react-reveal';

const SectionAmazon = () => {
  return (
    <P.StyledSection>
      <Fade>
        <Container>
          <P.StyledSectionTitle>
            <Trans>privacy_policy_amazon_title</Trans>
          </P.StyledSectionTitle>
          <P.StyledText>
            <Trans>privacy_policy_amazon_mentioned_above</Trans>
            <P.StyledLink href={"https://aws.amazon.com/blogs/security/all-aws-services-gdpr-ready/"}
                          target={"_blank"}>(https://aws.amazon.com/blogs/security/all-aws-services-gdpr-ready/)</P.StyledLink>
            <Trans>privacy_policy_amazon_aws_certified</Trans>
            <P.StyledLink href={"https://www.privacyshield.gov/participant?id=a2zt0000000TOWQAA4&status=Active"}
                          target={"_blank"}>(https://www.privacyshield.gov/participant?id=a2zt0000000TOWQAA4&status=Active)</P.StyledLink>
            <Trans>privacy_policy_amazon_eu_us</Trans>
          </P.StyledText>
        </Container>
      </Fade>
    </P.StyledSection>
  );
}

export default SectionAmazon;