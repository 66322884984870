import React from 'react';
import { Trans } from "react-i18next";
import * as P from './parts';
import { Container } from "styled-bootstrap-grid";
import Fade from 'react-reveal';

const SectionGeneralInfo = () => {
  return (
    <P.StyledBigSection>
      <Fade>
        <Container>
          <P.StyledTitle>
            <Trans>privacy_policy_general_info_title</Trans>
          </P.StyledTitle>
          <P.StyledSubtitle>
            <Trans>privacy_policy_general_info_effective_date</Trans>
          </P.StyledSubtitle>
          <P.StyledText>
            <Trans>privacy_policy_general_info_last_updated</Trans>
          </P.StyledText>
          <P.StyledText>
            <Trans>privacy_policy_general_info_has_created</Trans>
            <P.StyledLink href={"https://panel.mobd.pl/terms"}
                          target={"_blank"}>https://panel.mobd.pl/terms</P.StyledLink>
            <Trans>privacy_policy_general_info_by_accessing</Trans>
          </P.StyledText>
          <P.StyledText>
            <Trans>privacy_policy_general_info_we_reserve</Trans>
            <P.StyledLink href={"https://panel.mobd.pl/privacy"}
                          target={"_blank"}>https://panel.mobd.pl/privacy.</P.StyledLink>
            <Trans>privacy_policy_general_info_using_the_service</Trans>
          </P.StyledText>
        </Container>
      </Fade>
    </P.StyledBigSection>
  );
};

export default SectionGeneralInfo;