import * as React from "react"
import Page from "../components/Page";
import { Copyright, Footer, Nav, Partners } from "../components/partials";
import SEO from "../components/utils/SEO";
import {graphql} from "gatsby";
import {useTranslation} from "react-i18next";
import {
  SectionAmazon,
  SectionBreachProtocol,
  SectionCookiePolicy, SectionEuropeanUsers,
  SectionGeneralInfo,
  SectionInformationGathering, SectionInfoSharing, SectionModifyingInfo,
  SectionPersonalInfo, SectionSecurity
} from "../components/privacyPolicy";

const DemoPage = () => {
  const {t} = useTranslation();

  return (
    <Page>
      <SEO title={t("page_title_privacy_policy")} />
      <Nav />
      <SectionGeneralInfo />
      <SectionInformationGathering />
      <SectionCookiePolicy />
      <SectionPersonalInfo />
      <SectionInfoSharing />
      <SectionModifyingInfo />
      <SectionSecurity />
      <SectionBreachProtocol />
      <SectionEuropeanUsers />
      <SectionAmazon />
      <Footer />
      <Partners />
      <Copyright />
    </Page>
  );
};

export default DemoPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;