import React from "react";
import * as P from './parts';
import { Container } from "styled-bootstrap-grid";
import { Trans } from "react-i18next";
import Fade from 'react-reveal';

const SectionEuropeanUsers = () => {
  return (
    <P.StyledSection>
      <Fade>
        <Container>
          <P.StyledSectionTitle>
            <Trans>privacy_policy_eu_users_title</Trans>
          </P.StyledSectionTitle>
          <P.StyledText>
            <Trans>privacy_policy_eu_users_data_protection</Trans>
          </P.StyledText>
          <P.StyledList>
            <P.StyledListItem>
              <Trans>privacy_policy_eu_users_access</Trans>
            </P.StyledListItem>
            <P.StyledListItem>
              <Trans>privacy_policy_eu_users_rectification</Trans>
            </P.StyledListItem>
            <P.StyledListItem>
              <Trans>privacy_policy_eu_users_erasure</Trans>
            </P.StyledListItem>
            <P.StyledListItem>
              <Trans>privacy_policy_eu_users_restriction</Trans>
            </P.StyledListItem>
            <P.StyledListItem>
              <Trans>privacy_policy_eu_users_object</Trans>
            </P.StyledListItem>
            <P.StyledListItem>
              <Trans>privacy_policy_eu_users_data_portability</Trans>
            </P.StyledListItem>
          </P.StyledList>
          <P.StyledText>
            <Trans>privacy_policy_eu_users_see_below</Trans>
          </P.StyledText>
        </Container>
      </Fade>
    </P.StyledSection>
  );
};

export default SectionEuropeanUsers;