import React from "react";
import * as P from './parts';
import { Container } from "styled-bootstrap-grid";
import { Trans } from "react-i18next";
import Fade from 'react-reveal';

const SectionModifyingInfo = () => {
  return (
    <P.StyledSection>
      <Fade>
        <Container>
          <P.StyledSectionTitle>
            <Trans>privacy_policy_modifying_info_title</Trans>
          </P.StyledSectionTitle>
          <P.StyledText>
            <Trans>privacy_policy_modifying_info_update_info</Trans>
          </P.StyledText>
        </Container>
      </Fade>
    </P.StyledSection>
  );
}

export default SectionModifyingInfo;