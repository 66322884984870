import React from "react";
import * as P from './parts';
import { Container } from "styled-bootstrap-grid";
import { Trans } from "react-i18next";
import Fade from 'react-reveal';

const SectionSecurity = () => {
  return (
    <P.StyledSection>
      <Fade>
        <Container>
          <P.StyledSectionTitle>
            <Trans>privacy_policy_security_title</Trans>
          </P.StyledSectionTitle>
          <P.StyledText>
            <Trans>privacy_policy_security_safeguarding_info</Trans>
          </P.StyledText>
          <P.StyledText>
            <Trans>privacy_policy_security_username_password</Trans>
          </P.StyledText>
          <P.StyledText>
            <Trans>privacy_policy_security_data_stored</Trans>
            <P.StyledLink href={"https://aws.amazon.com/privacy/"}
                          target={"_blank"}>https://aws.amazon.com/privacy/</P.StyledLink>
          </P.StyledText>
        </Container>
      </Fade>
    </P.StyledSection>
  );
}

export default SectionSecurity;