import React from 'react';
import * as P from './parts';
import { Container } from "styled-bootstrap-grid";
import { Trans } from "react-i18next";
import Fade from 'react-reveal';

const SectionInformationGathering = () => {
  return (
    <P.StyledSection>
      <Fade>
        <Container>
          <P.StyledSectionTitle>
            <Trans>privacy_policy_information_gathering_title</Trans>
          </P.StyledSectionTitle>
          <P.StyledText>
            <Trans>privacy_policy_information_gathering_we_collect_information</Trans>
          </P.StyledText>
          <P.StyledList type={"decimal"}>
            <P.StyledListItem>
              <Trans>privacy_policy_information_gathering_personal_information</Trans>
            </P.StyledListItem>
            <P.StyledListItem>
              <Trans>privacy_policy_information_gathering_non-personal_information</Trans>
            </P.StyledListItem>
            <P.StyledListItem>
              <Trans>privacy_policy_information_gathering_we_may_further</Trans>
            </P.StyledListItem>
            <P.StyledListItem>
              <Trans>privacy_policy_information_gathering_we_automatically_receive</Trans>
            </P.StyledListItem>
            <P.StyledListItem>
              <Trans>privacy_policy_information_gathering_you_ask_us</Trans>
            </P.StyledListItem>
            <P.StyledListItem>
              <Trans>privacy_policy_information_gathering_access_all_data</Trans>
            </P.StyledListItem>
            <P.StyledListItem>
              <Trans>privacy_policy_information_gathering_update_all</Trans>
            </P.StyledListItem>
            <P.StyledListItem>
              <Trans>privacy_policy_information_gathering_you_maintain</Trans>
            </P.StyledListItem>
            <P.StyledListItem>
              <Trans>privacy_policy_information_gathering_data_portability</Trans>
            </P.StyledListItem>
          </P.StyledList>
        </Container>
      </Fade>
    </P.StyledSection>
  );
}

export default SectionInformationGathering;